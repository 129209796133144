import { ListIcon, ListItem, Text } from '@chakra-ui/react'
import React from 'react'

export const ListText = ({ text, url, icon }) => {
    return (
        <div>
            <ListItem>
                <ListIcon as={icon} color='green.500' />
                <Text fontSize='2xl' bg='gray.700' display='inline' color={'#5E81AC'} _hover={{ color: '#81A1C1' }} pl={'10px'}>
                    <a href={url} target='_blank' rel='noreferrer noopener'>{text}</a>
                </Text>
            </ListItem>
        </div>
    )
}
