import { Box, Text } from '@chakra-ui/react';

export const SpanText = ({ info }) => {
    return (
        <Box mb='2' lineHeight='1.33'>
            <Text fontSize='xl' bg='gray.700' display='inline' paddingStart={'10px'} boxDecorationBreak={'clone'}>
                {info}
            </Text>
        </Box>
    )
}
