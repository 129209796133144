import { AppRouter } from './routes/AppRouter';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        gray: {
            50: '#F7FAFC',
            700: '#2D3748',
            800: '#1A202C',
            900: '#171923'
        }
    }
});

export const Home = () => {
    return (
        <ChakraProvider theme={theme}>
            <AppRouter />
        </ChakraProvider>
    )
}