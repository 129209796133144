export const personal = [
    {
        id: 1,
        info: 'I am studying Systems and Computing Engineering at the Technological and Pedagogical University of Colombia (UPTC). I am currently in nineth semester.'
    },
    {
        id: 2,
        info: 'I am 21 years old. I currently live in Nobsa, Colombia. I really like watching anime, playing video games 👾 and the cats, for now I have 2 of them. 🐱'
    },
    {
        id: 3,
        info: 'This is my first personal website, I hope to improve it with the skills I gain in the short and long term.'
    }
];