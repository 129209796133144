import { Box, Container, List, SimpleGrid, Text } from '@chakra-ui/react';
import { MdOutlineMailOutline } from 'react-icons/md';
import { BsLinkedin, BsGithub, BsSpotify } from 'react-icons/bs';

import { SpanText } from './SpanText';
import { personal } from '../../data/info';
import { ListText } from './ListText';

export const Home = () => {

    return (
        <Container maxW='container.xl' bg='gray.800'>
            <SimpleGrid bg='gray.900' color='#D8DEE9' p='4' columns={2} spacing={'32'} >
                <Box>
                    <Box mb='25' mt='35'>
                        <Text fontSize='3xl' as='em' bg='gray.700' pl={'10px'}>Arge Niño</Text>
                    </Box>
                    {
                        personal.map(info => (
                            <SpanText key={info.id} {...info} />
                        ))
                    }
                </Box>
                <Box mt='77'>
                    <Box mb={'25'}>
                        <Text fontSize='2xl' as='em' bg='gray.700' pl={'10px'}>Say hi 😄</Text>
                    </Box>
                    <List spacing={3}>
                        <ListText text='darley1054@gmail.com' url='mailto:darley1054@gmail.com' icon={MdOutlineMailOutline} />
                        <ListText text='LinkedIn' url='https://www.linkedin.com/in/argeni%C3%B1o/' icon={BsLinkedin} />
                        <ListText text='Github' url='https://github.com/ArgeNH' icon={BsGithub} />
                        <ListText text='Music' url='https://open.spotify.com/playlist/2MGxF3MT7OYd37EacL7wHV?si=4ad0dc70775f4904' icon={BsSpotify} />
                    </List>
                </Box>
            </SimpleGrid>
        </Container>
    )
}