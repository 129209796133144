import { BrowserRouter, Route, Routes } from "react-router-dom"
import { About } from "../components/about/About"
import { Home } from "../components/home/Home"
import { Portfolio } from "../components/portfolio/Portfolio"


export const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />}/>
                <Route path='/about' element={<About />}/>
                <Route path='/portfolio' element={<Portfolio />}/>
            </Routes>
        </BrowserRouter>
    )
}
